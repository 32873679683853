@import 'theme';

@mixin default-font {
    font-family: $font;
    font-style: $font-style;
    font-weight: $font-weight;
}

@mixin default-h1 {
    font-family: $font;
    font-size: $font-size-h1;
    font-style: $font-style-h1;
    font-weight: $font-weight-h1;
    color: $h1-color;
}
